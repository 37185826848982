import React from 'react'
import Navbar from "./../../component/Other/Navbar"
import Footer from "./../../component/Other/Footer"
import LIstArticle from "./../../component/Page/LIstArticle"
import { Fragment } from 'react'

const About = () => {
    const blog = [
        {
            cover: "./../images/sdasd.png",
            title: "Home Care and Nursing Service",
            desc: "Experience the convenience of expert Home Care and Nursing services tailored to your needs.",
            link: "#!",
          },
          {
            cover: "./../images/Bg (1).png",
            title: "Diabetes & Blood Pressure Check-Up",
            desc: "Prioritize your health with our specialized Diabetes and Blood Pressure check-up services.",
            link: "#!",
          },
          {
            cover: "./../images/Bg (2).png",
            title: "Online Healthcare Treatment Facilities",
            desc: "Access healthcare from the convenience of your home with our Online Healthcare Facilities.",
            link: "#!",
          }
    ]
    return (
        <Fragment>
            <div className="overflow-hidden">
                <div className="position-relative bg__blue-2">
                    <img src="./../images/sa (3).png" className="images__or-1" alt="" />
                    <img src="./../images/sa (2).png" className="images__or-2" alt="" />

                    <Navbar />

                    <section className="position-relative z-2">
                        <div className="container text-center py-5">
                            <h2 className='medium font__size--60 text__60-1024 text__60-sm text__60-xxs  color__white'>About Us</h2>
                            <p className='normal font__size--16 text__16-1024 color__white'>Home / <span className='color__green'>About</span></p>
                        </div>
                    </section>
                </div>


                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <h5 className="bold font__size--18 text__18-1024 color__blue-1">About Us</h5>
                                <h2 className="medium font__size-58 text__50-1024 text__50-sm text__50-xs">Care and Compassion are Provided in Bulk</h2>
                                <p className="normal font__size--16 text__16-1024 color__gray-1">At MedHealth, our ethos revolves around care and compassion. We understand that health is a personal journey, and we are dedicated to providing tailored services that prioritize your well-being. our team is committed to creating a supportive environment for your health needs.</p>

                            </div>
                            <div className="col-md-6 mt-auto">
                                <p className="normal font__size--16 text__16-1024 color__gray-1"> With a focus on excellence and a team driven by empathy, we go beyond conventional health services. We believe in making a positive impact on your health and life, contributing to a healthier, happier community. Join us on this journey towards well-being, where your health is our priority.</p>
                            </div>
                        </div>

                        <div className="d-flex flex-wrap flex-md-nowrap">
                            <img src="./../images/Image (4sa).png" className='w-md-50 w-100 mb-4 mb-md-0' alt="" />
                            <div className='ml-md-4 w-100 w-md-50'>
                                <img src="./../images/Image (5).png" className='w-100 mb-4' alt="" />
                                <img src="./../images/Image (6).png" className='w-100' alt="" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className=''>
                    <div className="container">
                        <div className="text-center mb-5">
                            <h5 className="bold font__size--18 text__18-1024 color__blue-1">About Us</h5>
                            <h2 className="medium font__size-58 text__50-1024 text__50-sm text__50-xs">We Are Specialize in Medical <br className='d-none d-md-block' /> Diagnositics</h2>
                        </div>

                        <div className="row mb-4">
                            <div className="col-md-6 mb-4 mb-md-0">
                                <p className="normal font__size--16 text__16-1024 color__gray-1">At MedHealth, our specialization in medical diagnostics sets us apart as a leader in precise and comprehensive healthcare. Our cutting-edge diagnostic services utilize advanced technology and a skilled team to provide accurate assessments, enabling early detection and effective treatment planning.</p>

                            </div>
                            <div className="col-md-6 mt-auto">
                                <p className="normal font__size--16 text__16-1024 color__gray-1">We prioritize the accuracy and efficiency of our diagnostic procedures. Our commitment to excellence in medical diagnostics ensures that every patient receives the highest standard of care, contributing to better health outcomes and a path towards optimal well-being. </p>
                            </div>
                        </div>

                        <img src="./../images/Group 1.png" className='w-100 mb-5' alt="" />

                        <div className="d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-center">
                            <div className="d-flex align-items-center justify-content-sm-center justify-content-lg-start mb-4 mb-lg-0 w-sm-100 mr-0 mr-md-3 mr-lg-5">
                                <h5 className="mb-0 medium font__size--54 text__50-1024 text__50-sm color__green">4.9</h5>
                                <p className="mb-0 normal font__size--16 text__16-1024 color__black ml-3">Overall Rating, based on <br className='d-none d-xl-block' /> <span className="semi-bold color__green">3500+</span> Reviews</p>
                            </div>
                            <div className="d-flex align-items-center justify-content-sm-center justify-content-lg-start mb-4 mb-lg-0 w-sm-100 mr-0 mr-md-3 mr-lg-5">
                                <h5 className="mb-0 medium font__size--54 text__50-1024 text__50-sm color__green">99<sup className="medium font__size--32 color__green">%</sup></h5>
                                <p className="mb-0 normal font__size--16 text__16-1024 color__black ml-3">Positive Review</p>
                            </div>
                            <div className="d-flex align-items-center justify-content-sm-center justify-content-lg-start mb-4 mb-lg-0 w-sm-100 mr-0 mr-md-3 mr-lg-5">
                                <h5 className="mb-0 medium font__size--54 text__50-1024 text__50-sm color__green">9.2<sup className="medium font__size--32 color__green">k</sup></h5>
                                <p className="mb-0 normal font__size--16 text__16-1024 color__black ml-3">Happy Patients</p>
                            </div>
                            <div className="d-flex align-items-center justify-content-sm-center justify-content-lg-start mb-4 mb-lg-0 w-sm-100">
                                <h5 className="mb-0 medium font__size--54 text__50-1024 text__50-sm color__green">312</h5>
                                <p className="mb-0 normal font__size--16 text__16-1024 color__black ml-3">Health Departments</p>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="bg__gray-2">
                    <div className="pb-sm-5">
                        <LIstArticle blog={blog} title={"Our Articles About Health"} about={"Blog & News"} />
                    </div>

                    <Footer />
                </div>
            </div >
        </Fragment >
    )
}

export default About