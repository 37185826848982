import React from 'react'
import { Fragment } from 'react'
import ServiceSec from "./../../component/Page/Service"
import Navbar from "./../../component/Other/Navbar"
import Footer from "./../../component/Other/Footer"
import LIstArticle from "./../../component/Page/LIstArticle"
import Appoinment from '../../component/Page/Appoinment'
import Operating from '../../component/Page/Operating'

const Service = () => {
    return (
        <Fragment>
            <div className="overflow-hidden">
                <div className="position-relative bg__blue-2">
                    <img src="./../images/sa (3).png" className="images__or-1" alt="" />
                    <img src="./../images/sa (2).png" className="images__or-2" alt="" />

                    <Navbar />

                    <section className="position-relative z-2">
                        <div className="container text-center py-5">
                            <h2 className='medium font__size--60 text__60-1024 text__60-sm text__60-xxs color__white'>Our Service</h2>
                            <p className='normal font__size--16 text__16-1024 color__white'>Home / <span className='color__green'>Service</span></p>
                        </div>
                    </section>
                </div>

                <ServiceSec />

                <section>
                    <div className="container">
                        <div className="text-center mb-5 position-relative z-2">
                            <h5 className="bold font__size--18 text__18-1024 color__blue-1">Our Place</h5>
                            <h2 className="medium font__size-58 text__50-1024 text__50-sm">Best Place for Independent <br className='d-none d-md-block' /> Seniors</h2>
                        </div>

                        <div className="wrapper__video-cover position-relative">
                            <img src="./../images/Group 163000 (1).png" className='play pointer' alt="" />
                            <img src="./../images/sfsfsf.png" className='w-100' alt="" />
                        </div>
                    </div>
                </section>

                <Operating />

                <div className="bg__gray-2">
                    <Appoinment />

                    <Footer />
                </div>
            </div >
        </Fragment >
    )
}

export default Service