import React from 'react'
import CardBlog from '../Card/CardBlog'

const LIstArticle = (props) => {
    return (
        <section className={props.bg ? props.bg : ""}>
            <div className="container">
                <div className="text-center mb-5">
                    <h5 className="bold font__size--18 text__18-1024 color__blue-1">{props.about}</h5>
                    <h2 className="medium font__size-58 text__50-1024 text__50-sm text__50-xs">{props.title}</h2>
                </div>

                <div className="row">
                    {
                        props.blog.map((obj) => {
                            return <div className="col-md-6 col-lg-4 mb-4">
                                <CardBlog data={obj} />
                            </div>
                        })
                    }

                </div>
            </div>
        </section>
    )
}

export default LIstArticle