import React, { Component, Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { ArrowDownIcons } from "../Icon/Icons";
const Navbar = (props) => {
  const [toogleMenu, setToogleMenu] = useState(false)
  return (
    <Fragment>
      <div className={"wrapper__side-nav-mobile d-flex d-xl-none " + (toogleMenu ? "active" : "")}>
        <div className="d-flex flex-wrap w-100">
          <div className="w-100">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <NavLink
                to="/"
                exact
                className="navbar-brand semi-bold font__size--20 text__20-1024 semi-bold color__green "
              >
                Med<span className="color__black">Health</span>

              </NavLink>
              <img src="./../images/x-regular-24.png" onClick={() => setToogleMenu(!toogleMenu)} className="pointer" alt="" />
            </div>

            <div className="menu">
              <NavLink to="/" className="medium font__size--14 text__14-1024 color__black">Home</NavLink>
              <hr />
              <NavLink to="/about" className="medium font__size--14 text__14-1024 color__black">About</NavLink>
              <hr />
              <NavLink
                to="/service" className="medium font__size--14 text__14-1024 color__black">Service</NavLink>
              <hr />
              <NavLink
                to="/blog" className="medium font__size--14 text__14-1024 color__black">Blog</NavLink>
              <hr />
              <NavLink to="/doctor" className="medium font__size--14 text__14-1024 color__black">Doctor</NavLink>
              <hr />
              <NavLink to="/appoinment" className="medium font__size--14 text__14-1024 color__black">Appoinment</NavLink>
              <hr />
              <NavLink to="/faq" className="medium font__size--14 text__14-1024 color__black">FAQ</NavLink>
              <hr />
              <NavLink to="/service/detail" className="medium font__size--14 text__14-1024 color__black">Service Detail</NavLink>
              <hr />
              <NavLink to="/blog/detail" className="medium font__size--14 text__14-1024 color__black">Blog Detail</NavLink>
              <hr />
              <NavLink to="/testimonial" className="medium font__size--14 text__14-1024 color__black">Testimonial</NavLink>
              <hr />
              <a href="#!" className={"semi-bold rounded-0 font__size--14 text__14-1024 no__opacity shadow btn btn__outlined--green color__green"}>Contact Us</a>
            </div>


          </div>
        </div>
      </div >
      <div className="bg__wrap-menu d-xl-none" onClick={() => setToogleMenu(!toogleMenu)}></div>
      <nav
        className={"navbar navbar-expand-xl wrapper__navbar position-relative z-2 " + (props.auth ? "auth" : "")}
        data-aos="fade-down"
        data-aos-duration="1000"
        data-aos-delay="300"
      >
        <div className="container position-relative">
          <NavLink
            to="/"
            exact
            className="navbar-brand font__size--20 text__20-1024 semi-bold color__green"
          >
            Med<span className="color__white">Health</span>
          </NavLink>

          <div
            className="collapse navbar-collapse wrapper__navbar-menu ml-3"
            id="navbarSupportedContent"
          >

            <ul className="navbar-nav menu__center">
              <li className="nav-item">
                <NavLink
                  to="/"
                  exact
                  className="nav-link medium font__size--14 text__14-1024 color__white"
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/about"
                  className="nav-link medium font__size--14 text__14-1024 color__white"
                >
                  About
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/service"
                  className="nav-link medium font__size--14 text__14-1024 color__white"
                >
                  Service
                </NavLink>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link medium font__size--14 text__14-1024 color__white wrapper__drodown"
                >
                  <div className="arrow pointer d-flex align-items-center" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span className="mr-2">Page</span> <ArrowDownIcons />
                  </div>
                  <div className="dropdown-menu dropdown-menu-right">
                    <NavLink to="/doctor" className="dropdown-item">Doctor</NavLink>
                    <NavLink to="/appoinment" className="dropdown-item">Appoinment</NavLink>
                    <NavLink to="/testimonial" className="dropdown-item">Testimonial</NavLink>
                    <NavLink to="/faq" className="dropdown-item">FAQ</NavLink>
                    <NavLink to="/service/detail" className="dropdown-item">Service Detail</NavLink>
                    <NavLink to="/blog/detail" className="dropdown-item">Blog Detail</NavLink>
                  </div>
                </a>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/blog"
                  className="nav-link medium font__size--14 text__14-1024 color__white"
                >
                  Blog
                </NavLink>
              </li>
            </ul>


          </div>


          <a href="#!" className={"semi-bold rounded-0 font__size--14 text__14-1024 no__opacity shadow btn btn__outlined--white h__green color__white d-none d-xl-block"}>Contact Us</a>

          <div
            onClick={() => setToogleMenu(!toogleMenu)}
            className="nav__button d-xl-none position-relative ml-auto flex-shrink-0"
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </nav>
    </Fragment >
  );
};

export default Navbar;
