import React from 'react'

const CardTestimonial = () => {
    return (
        <div className="wrapper__card-testimonial">
            <div className="wrap mb-4">
                <p className='light lh-2 font__size--20 text__20-1024 mb-0'>“Exceptional experience with MedHealth! The comprehensive medical diagnostics provided precise insights into my health. Professionalism, accuracy, and personalized care—truly a standout in healthcare services.”</p>
            </div>
            <div className="d-flex align-items-center user">
                <img src="./../images/Ellipse 2.png" alt="" />
                <span className='ml-3 medium font__size--18 text__18-1024'>Brooklyn Simmons</span>
            </div>
        </div>
    )
}

export default CardTestimonial