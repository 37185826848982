import React from 'react'
import { Fragment } from 'react'
import Navbar from "./../../component/Other/Navbar"
import Footer from "./../../component/Other/Footer"
import CardTestimonial from '../../component/Card/CardTestimonial'

const Testimonial = () => {
    return (
        <Fragment>
            <div className="overflow-hidden">
                <div className="position-relative bg__blue-2">
                    <img src="./../images/sa (3).png" className="images__or-1" alt="" />
                    <img src="./../images/sa (2).png" className="images__or-2" alt="" />

                    <Navbar />

                    <section className="position-relative z-2">
                        <div className="container text-center py-5">
                            <h2 className='medium font__size--60 text__60-1024 text__60-sm text__60-xxs color__white'>Testimonial</h2>
                            <p className='normal font__size--16 text__16-1024 color__white'>Home / <span className='color__green'>Testimonial</span></p>
                        </div>
                    </section>
                </div>

                <section>
                    <div className="container">
                        <div className="text-center mb-5 position-relative z-2">
                            <h5 className="bold font__size--18 text__18-1024 color__blue-1">Testimonial</h5>
                            <h2 className="medium font__size-58 text__50-1024 text__50-sm">What They Said</h2>
                        </div>

                        <div className="row justify-content-center mb-5">
                            <div className="col-lg-10 col-xl-8">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-5">
                                            <CardTestimonial />
                                        </div>
                                        <div className="mb-5">
                                            <CardTestimonial />
                                        </div>
                                        <div className="mb-md-5">
                                            <CardTestimonial />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mt-5">
                                            <CardTestimonial />
                                        </div>
                                        <div className="mt-5">
                                            <CardTestimonial />
                                        </div>
                                        <div className="mt-5">
                                            <CardTestimonial />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </div >
        </Fragment >
    )
}

export default Testimonial