import React from 'react'
import { Fragment } from 'react'
import Navbar from "./../../component/Other/Navbar"
import Footer from "./../../component/Other/Footer"
import MakeAppoinment from '../../component/Page/Appoinment'

const Appoinment = () => {
    return (
        <Fragment>
            <div className="overflow-hidden">
                <div className="position-relative bg__blue-2">
                    <img src="./../images/sa (3).png" className="images__or-1" alt="" />
                    <img src="./../images/sa (2).png" className="images__or-2" alt="" />

                    <Navbar />

                    <section className="position-relative z-2">
                        <div className="container text-center py-5">
                            <h2 className='medium font__size--60 text__60-1024 text__60-sm text__60-xxs color__white'>Make Appoinment</h2>
                            <p className='normal font__size--16 text__16-1024 color__white'>Home / <span className='color__green'>Appoinment</span></p>
                        </div>
                    </section>
                </div>

                <div className="bg__gray-2">
                    <MakeAppoinment />

                    <Footer />
                </div >
            </div >
        </Fragment >
    )
}

export default Appoinment