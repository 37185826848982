import React from 'react'
import { NavLink } from 'react-router-dom'

const CardBlog = (props) => {
    return (
        <div className='wrapper__card-blog'>
            <img src={props.data.cover} className='cover mb-3' alt="" />
            <h5 className='medium font__size--28 text__28-1024'>{props.data.title}</h5>
            <p className='normal font__size--16 text__16-1024 color__gray-1'>{props.data.desc}</p>
            <a href={props.data.link} className='d-inline-block'>
                <NavLink to="/blog/detail" className="d-flex align-items-center bold font__size--18 color__green ">
                    Read More <img src="./../images/right-arrow 2.png" className='ml-2' alt="" />
                </NavLink>
            </a>
        </div>
    )
}

export default CardBlog