import React from 'react'
import { Fragment } from 'react'
import Navbar from "./../../component/Other/Navbar"
import Footer from "./../../component/Other/Footer"
import LIstArticle from '../../component/Page/LIstArticle'

const Detail = () => {
    const blog = [
        {
            cover: "./../images/sdasd.png",
            title: "Home Care and Nursing Service",
            desc: "Experience the convenience of expert Home Care and Nursing services tailored to your needs.",
            link: "#!",
          },
          {
            cover: "./../images/Bg (1).png",
            title: "Diabetes & Blood Pressure Check-Up",
            desc: "Prioritize your health with our specialized Diabetes and Blood Pressure check-up services.",
            link: "#!",
          },
          {
            cover: "./../images/Bg (2).png",
            title: "Online Healthcare Treatment Facilities",
            desc: "Access healthcare from the convenience of your home with our Online Healthcare Facilities.",
            link: "#!",
          }
    ]

    const sideBlock = [
        {
            cover: "./../images/dt (1).png",
            title: "Get the Home care and nursing service"
        },
        {
            cover: "./../images/Bg (2).png",
            title: "Diabetes & blood pressor check up"
        },
        {
            cover: "./../images/Bg (1).png",
            title: "Online Health care treatment Facilities"
        },
        {
            cover: "./../images/jh (1).png",
            title: "Diabetes & blood pressor check up"
        },
    ]
    return (
        <Fragment>
            <div className="overflow-hidden">
                <div className="position-relative bg__blue-2">
                    <img src="./../images/sa (3).png" className="images__or-1" alt="" />
                    <img src="./../images/sa (2).png" className="images__or-2" alt="" />

                    <Navbar />

                    <section className="position-relative z-2">
                        <div className="container text-center py-5">
                            <h2 className='medium font__size--60 text__60-1024 text__60-sm text__60-xxs color__white'>Detail Article</h2>
                            <p className='normal font__size--16 text__16-1024 color__white'>Home / <span>Blog</span> <span className='color__green'>/ Get the Home care and nursing service</span>
                            </p>
                        </div>
                    </section>
                </div>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 mb-5 mb-md-0">
                                <img src="./../images/dt (1).png" className='images__detail-head mb-4' alt="" />
                                <h2 className='medium font__size--58 text__50-1024 text__50-sm'>Get the Home care and nursing service</h2>
                                <div className="d-flex align-items-center mb-4">
                                    <span className='bold font__size--18 text__18-1024 color__blue-1'>Health</span>
                                    <span className='wrapper__dot mx-2'></span>
                                    <span className='bold font__size--18 text__18-1024 color__gray-1'>25 Nov 2022</span>
                                </div>

                                <p className='normal font__size--20 text__20-1024 color__gray-1 lh-2 mb-4'>At MedHealth, we understand the importance of personalized and compassionate healthcare, which is why our home care and nursing services are designed to bring expert medical attention directly to your doorstep. In this blog post, we'll explore the various aspects that make our home care services stand out, from the convenience of at-home consultations to the development of tailored healthcare plans. </p>
                                <p className='normal font__size--20 text__20-1024 color__gray-1 lh-2 mb-4'>Our home care services aim to redefine convenience by eliminating the need for frequent visits to medical facilities. Learn how our team seamlessly integrates medical care into the comfort of your home, providing not only expert services but also a sense of familiarity and ease.</p>


                                <div className="wrapper__left-wrap position-relative mb-4">
                                    <p className='mb-0 normal font__size--24 text__24-1024 lh-2'>In the sanctuary of your home, we deliver not just medical care but a compassionate touch, ensuring that each healthcare service is a step towards your well-being.' - MedHealth </p>
                                </div>

                                <p className='normal font__size--20 text__20-1024 color__gray-1 lh-2 mb-4'>Discover the unique advantages of receiving nursing services in the familiar environment of your home. We'll discuss how our skilled nursing professionals prioritize compassionate care, fostering a healing atmosphere that contributes positively to the overall well-being of our patients.</p>

                                <img src="./../images/dt (2).png" className='images__detail-body mb-4' alt="" />

                                <p className='normal font__size--20 text__20-1024 color__gray-1 lh-2 mb-4'>Every individual has unique healthcare needs, and our blog post delves into how our home care services are tailored to address specific requirements. From personalized consultations to the development of customized healthcare plans, we ensure that our services align with your health goals.</p>
                                <p className='normal font__size--20 text__20-1024 color__gray-1 lh-2 mb-4'>Join us as we explore the transformative impact of home care and nursing services at MedHealth. We're committed to providing not just medical care but a holistic and comforting healthcare experience for you and your loved ones. Stay tuned for insights into how our services bring convenience, compassion, and personalized care directly to your doorstep.</p>

                                <div className="d-flex align-items-center">
                                    <span className='medium font__size--18 text__18-1024'>Share</span>
                                    <div className="d-flex align-items-center ml-3">
                                        <a href="#!">
                                            <img src="./../images/FB.png" alt="" />
                                        </a>
                                        <a href="#!" className='mx-3'>
                                            <img src="./../images/IG.png" alt="" />
                                        </a>
                                        <a href="#!">
                                            <img src="./../images/WA.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-xl-3 offset-xl-1">
                                <div className="mb-5">
                                    <h4 className='medium font__size--24 text__24-1024 mb-4'>Search</h4>
                                    <input type="text" placeholder='Search title...' className='wrapper__search-field' />
                                </div>
                                <div className="mb-5">
                                    <h4 className='medium font__size--24 text__24-1024 mb-4'>Recent Article</h4>


                                    {
                                        sideBlock.map((obj) => {
                                            return <Fragment>
                                                <div className="wrapper__block-side d-flex align-items-center">
                                                    <img src={obj.cover} alt="" />
                                                    <h5 className='medium font__size--16 text__16-1024 mb-0 ml-3'>{obj.title}</h5>
                                                </div>
                                                <hr />
                                            </Fragment>
                                        })
                                    }
                                </div>

                                <div className="mb-5">
                                    <h4 className='medium font__size--24 text__24-1024 mb-4'>Category</h4>

                                    <div className="wrapper__tag-blog">
                                        <a href="#!" className='medium font__size--16 text__16-1024 color__black'>Health</a>
                                        <a href="#!" className='medium font__size--16 text__16-1024 color__black'>Lifestyle</a>
                                        <a href="#!" className='medium font__size--16 text__16-1024 color__black'>Body</a>
                                        <a href="#!" className='medium font__size--16 text__16-1024 color__black'>Sleep</a>
                                        <a href="#!" className='medium font__size--16 text__16-1024 color__black'>Food</a>
                                        <a href="#!" className='medium font__size--16 text__16-1024 color__black'>Hospital</a>
                                    </div>
                                </div>

                                <div className="mb-5">
                                    <h4 className='medium font__size--24 text__24-1024 mb-4'>Popular Post</h4>

                                    <ul className='wrapper__list-side-menu'>
                                        <li>
                                            <a href="#!" className='normal font__size--16 text__16-1024 color__black'>Get the Home care and nursing service</a>
                                        </li>
                                        <li>
                                            <a href="#!" className='normal font__size--16 text__16-1024 color__black'>Diabetes & blood pressor check up</a>
                                        </li>
                                        <li>
                                            <a href="#!" className='normal font__size--16 text__16-1024 color__black'>Online Health care treatment Facilities</a>
                                        </li>
                                        <li>
                                            <a href="#!" className='normal font__size--16 text__16-1024 color__black'>Diabetes & blood pressor check up</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <LIstArticle blog={blog} title={"Relate Articles"} about={"Blog & News"} />


                <Footer />
            </div >
        </Fragment >
    )
}

export default Detail