import React from 'react'
import Slider from "react-slick";

const SliderTestimonial = () => {
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const data = [1, 2, 3]

    return (
        <div className="row justify-content-center">
            <div className="col-md-10 col-lg-7">
                <Slider {...settings} className="wrapper__slider-testimoni">
                    {
                        data.map((obj) => {
                            return <div className="items text-center">
                                <div className="user">
                                    <img src="./../images/Ellipse 2.png" alt="" className="profile" />
                                    <h5 className='medium font__size--18 text__18-1024 color__white my-2'>Robert Fox</h5>
                                    <div className="rating d-flex align-items-center justify-content-center mb-4">
                                        <img src="./../images/Icon - Star.png" alt="" />
                                        <img src="./../images/Icon - Star.png" alt="" />
                                        <img src="./../images/Icon - Star.png" alt="" />
                                        <img src="./../images/Icon - Star.png" alt="" />
                                        <img src="./../images/Icon - Star.png" alt="" />
                                    </div>

                                    <p className='mb-0 medium font__size--18 text__18-1024 color__white font-italic'>MedHealth has been a game-changer for me. Their personalized care and commitment to well-being make them my go-to for all healthcare needs. A trusted partner in my health journey.</p>

                                </div>
                            </div>
                        })
                    }
                </Slider>
            </div>
        </div>
    )
}

export default SliderTestimonial