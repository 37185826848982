import React from 'react'
import { NavLink } from 'react-router-dom'

const Footer = () => {
    return (
        <section className="bg__blue-2 mt__17 pb-4">
            <div className="container">
                <div className="wrapper__appoinment bg__green position-relative mt__min-17">
                    <img src="./../images/sa (1).png" className="images__doc-fot d-none d-lg-block" alt="" />
                    <img src="./../images/sa (3).png" className="images__or-1" alt="" />
                    <img src="./../images/sa (2).png" className="images__or-2" alt="" />
                    <div className="position-relative z-2">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-4">
                                <h2 className="medium font__size--58 text__50-1024 text__50-sm text__50-xs color__white">Looking for professinal & trusted <span className="color__blue-1">medical <br className='d-none d-xl-block' /> healthcare?</span></h2>
                                <p className="normal font__size--20 text__20-1024 color__white">Don’t Hasitate To Contact Us</p>
                                <NavLink to="/appoinment" className="medium font__size--16 text__16-1024 btn btn__blue-2 px-4 color__white rounded-0 shadow">Make Appoinment</NavLink>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-5">
                    <div className="row">
                        <div className="col-md-4 mb-4 mb-md-0">
                            <div className="d-flex flex-wrap h-100">
                                <h3 className="medium font__size--28 text__28-1024 color__white w-100"><span className="color__green">Med</span>Health</h3>
                                <p className="mb-0 normal font__size--14 text__14-1024 color__white align-self-end d-none d-md-block">2022 Medhealth - All rights reserved </p>
                            </div>
                        </div>
                        <div className="col-6 mb-4 mb-md-0 col-md-2">
                            <ul className="wrapper__list-menu">
                                <li><NavLink to="/" className="normal font__size--14 text__14-1024 color__white">Home</NavLink></li>
                                <li><NavLink to="/about" className="normal font__size--14 text__14-1024 color__white">About</NavLink></li>
                                <li><NavLink to="/service" className="normal font__size--14 text__14-1024 color__white">Service</NavLink></li>
                                <li><NavLink to="/blog" className="normal font__size--14 text__14-1024 color__white">Blog</NavLink></li>
                            </ul>

                        </div>
                        <div className="col-6 mb-4 mb-md-0 col-md-2">
                            <ul className="wrapper__list-menu">
                                <li><NavLink to="/doctor" className="normal font__size--14 text__14-1024 color__white">Our Doctors</NavLink></li>
                                <li><NavLink to="/testimonial" className="normal font__size--14 text__14-1024 color__white">Testimonial</NavLink></li>
                                <li><NavLink to="/faq" className="normal font__size--14 text__14-1024 color__white">FAQ</NavLink></li>
                                <li><NavLink to="/appoinment" className="normal font__size--14 text__14-1024 color__white">Make Appoinment</NavLink></li>
                            </ul>
                        </div>
                        <div className="col-md-4 mb-4 mb-md-0">
                            <div className="d-flex flex-wrap h-100">
                                <ul className="wrapper__list-menu w-100 mb-4 mb-md-0">
                                    <li>
                                        <a href="#!" className="normal font__size--14 text__14-1024 color__white">
                                            <div className="d-flex align-items-center">
                                                <img src="./../images/df (1).png" alt="" /> <span className="ml-2">medhealth@gmail.com</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#!" className="normal font__size--14 text__14-1024 color__white">
                                            <div className="d-flex align-items-center">
                                                <img src="./../images/df (6).png" alt="" /> <span className="ml-2">+123 456 789</span>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                                <div className="wrapper__sosmed d-flex align-items-center align-self-end">
                                    <a href="#!">
                                        <img src="./../images/df (5).png" alt="" />
                                    </a>
                                    <a href="#!">
                                        <img src="./../images/df (4).png" alt="" />
                                    </a>
                                    <a href="#!">
                                        <img src="./../images/df (3).png" alt="" />
                                    </a>
                                    <a href="#!">
                                        <img src="./../images/df (2).png" alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p className="mb-0 normal font__size--14 text__14-1024 color__white align-self-end d-block d-md-none">2022 Medhealth - All rights reserved </p>
                </div>
            </div>
        </section>

    )
}

export default Footer