import React from 'react'
import { Fragment } from 'react'
import Navbar from "./../../component/Other/Navbar"
import Footer from "./../../component/Other/Footer"
import LIstArticle from '../../component/Page/LIstArticle'

const Blog = () => {
    const blog = [
        {
            cover: "./../images/sdasd.png",
            title: "Get the Home care and nursing service",
            desc: "Experience the convenience of expert Home Care and Nursing services tailored to your needs.",
            link: "#!",
        },
        {
            cover: "./../images/Bg (1).png",
            title: "Diabetes & blood pressor check up",
            desc: "Prioritize your health with our specialized Diabetes and Blood Pressure check-up services.",
            link: "#!",
        },
        {
            cover: "./../images/Bg (2).png",
            title: "Online Health care treatment Facilities",
            desc: "Access healthcare from the convenience of your home with our Online Healthcare Facilities.",
            link: "#!",
        },
        {
            cover: "./../images/jh (1).png",
            title: "Get the Home care and nursing service",
            desc: "Experience the convenience of expert Home Care and Nursing services tailored to your needs.",
            link: "#!",
        },
        {
            cover: "./../images/jh (6).png",
            title: "Diabetes & blood pressor check up",
            desc: "Prioritize your health with our specialized Diabetes and Blood Pressure check-up services.",
            link: "#!",
        },
        {
            cover: "./../images/jh (5).png",
            title: "Online Health care treatment Facilities",
            desc: "Access healthcare from the convenience of your home with our Online Healthcare Facilities.",
            link: "#!",
        },
        {
            cover: "./../images/jh (4).png",
            title: "Get the Home care and nursing service",
            desc: "Experience the convenience of expert Home Care and Nursing services tailored to your needs.",
            link: "#!",
        },
        {
            cover: "./../images/jh (3).png",
            title: "Diabetes & blood pressor check up",
            desc: "Prioritize your health with our specialized Diabetes and Blood Pressure check-up services.",
            link: "#!",
        },
        {
            cover: "./../images/jh (2).png",
            title: "Online Health care treatment Facilities",
            desc: "Access healthcare from the convenience of your home with our Online Healthcare Facilities.",
            link: "#!",
        },
    ]
    return (
        <Fragment>
            <div className="overflow-hidden">
                <div className="position-relative bg__blue-2">
                    <img src="./../images/sa (3).png" className="images__or-1" alt="" />
                    <img src="./../images/sa (2).png" className="images__or-2" alt="" />

                    <Navbar />

                    <section className="position-relative z-2">
                        <div className="container text-center py-5">
                            <h2 className='medium font__size--60 text__60-1024 text__60-sm text__60-xxs color__white'>Our Blog</h2>
                            <p className='normal font__size--16 text__16-1024 color__white'>Home / <span className='color__green'>Blog</span></p>
                        </div>
                    </section>
                </div>

                <LIstArticle blog={blog} title={"Recent Articles and News"} about={"Blog & News"} />

                <Footer />
            </div >
        </Fragment >
    )
}

export default Blog