import React, { Fragment } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
} from "react-router-dom";

import Homepage from "./homepage/index";
import About from "./homepage/About";
import Service from "./homepage/Service";
import Blog from "./homepage/Blog";
import Doctor from "./homepage/Doctor";
import Testimonial from "./homepage/Testimonial";
import Faq from "./homepage/Faq";
import Appoinment from "./homepage/Appoinment";
import Detail from "./homepage/Detail";
import DetailService from "./homepage/DetailService";

const Index = (props) => {
  return (
    <Fragment>
      <Router forceRefresh>
        <Routes>
        <Route path="/" element={<Homepage />}/>
          <Route path="/about" element={<About />}/>
          <Route path="/service" element={<Service />}/>
          <Route path="/blog" element={<Blog />}/>
          <Route path="/service/detail" element={<DetailService />}/>
          <Route path="/blog/detail" element={<Detail />}/>
          <Route path="/doctor" element={<Doctor />}/>
          <Route path="/testimonial" element={<Testimonial />}/>
          <Route path="/faq" element={<Faq />}/>
          <Route path="/appoinment" element={<Appoinment />}/>
        </Routes>
      </Router>
    </Fragment>
  );
};

export default Index;
