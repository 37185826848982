import React from 'react'

const Operating = () => {
    return (
        <section className='bg__blue-2'>
            <div className="container position-relative">
                <img src="./../images/sdsadsa.png" className='images__doc-fot min-80 max-450 d-none d-md-block' alt="" />
                <div className="row  position-relative z-2">
                    <div className="col-md-7 offset-md-5">
                        <h5 className="bold font__size--18 text__18-1024 color__blue-1">Operating Hours</h5>
                        <h2 className="medium font__size-58 text__50-1024 text__50-sm color__white mb-4">A great system <br className='d-none d-sm-block' /> application for hospital center & health care</h2>
                        <div className="wrapper__date-hours bg__green">
                            <div className="d-flex align-items-center justify-content-between item">
                                <h5 className='medium font__size--16 text__16-1024 color__white mb-0'>Monday</h5>
                                <h5 className='medium font__size--16 text__16-1024 color__white mb-0'>08:00 am - 06:00 pm</h5>
                            </div>
                            <div className="d-flex align-items-center justify-content-between item">
                                <h5 className='medium font__size--16 text__16-1024 color__white mb-0'>Tuesday</h5>
                                <h5 className='medium font__size--16 text__16-1024 color__white mb-0'>07:00 am - 06:00 pm</h5>
                            </div>
                            <div className="d-flex align-items-center justify-content-between item">
                                <h5 className='medium font__size--16 text__16-1024 color__white mb-0'>Wednesday</h5>
                                <h5 className='medium font__size--16 text__16-1024 color__white mb-0'>08:00 am - 06:00 pm</h5>
                            </div>
                            <div className="d-flex align-items-center justify-content-between item">
                                <h5 className='medium font__size--16 text__16-1024 color__white mb-0'>Thursday</h5>
                                <h5 className='medium font__size--16 text__16-1024 color__white mb-0'>08:00 am - 06:00 pm</h5>
                            </div>
                            <div className="d-flex align-items-center justify-content-between item">
                                <h5 className='medium font__size--16 text__16-1024 color__white mb-0'>Friday</h5>
                                <h5 className='medium font__size--16 text__16-1024 color__white mb-0'>07:00 am - 05:00 pm</h5>
                            </div>
                            <div className="d-flex align-items-center justify-content-between item">
                                <h5 className='medium font__size--16 text__16-1024 color__white mb-0'>Saturday</h5>
                                <h5 className='medium font__size--16 text__16-1024 color__white mb-0'>08:00 am - 05:00 pm</h5>
                            </div>
                            <div className="d-flex align-items-center justify-content-between item">
                                <h5 className='medium font__size--16 text__16-1024 color__white mb-0'>Sunday</h5>
                                <h5 className='medium font__size--16 text__16-1024 color__white mb-0'>Close</h5>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Operating