import React from 'react'
import { Fragment } from 'react'
import Navbar from "./../../component/Other/Navbar"
import Footer from "./../../component/Other/Footer"
import AccordionFaq from '../../component/Accordion/AccordionFaq'

const Faq = () => {
    const data = [
        {
            title: "Is there a free trial available?",
            desc: "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible."
        },
        {
            title: "Can I change my plan later?",
            desc: "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible."
        },
        {
            title: "What is your cancellation policy?",
            desc: "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible."
        },
        {
            title: "Can other info be added to an invoice?",
            desc: "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible."
        },
        {
            title: "How does billing work?",
            desc: "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible."
        },
        {
            title: "How do I change my account email?",
            desc: "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible."
        },
    ]
    return (
        <Fragment>
            <div className="overflow-hidden">
                <div className="position-relative bg__blue-2">
                    <img src="./../images/sa (3).png" className="images__or-1" alt="" />
                    <img src="./../images/sa (2).png" className="images__or-2" alt="" />

                    <Navbar />

                    <section className="position-relative z-2">
                        <div className="container text-center py-5">
                            <h2 className='medium font__size--60 text__60-1024 text__60-sm text__60-xxs color__white'>Frequently Asked Questions</h2>
                            <p className='normal font__size--16 text__16-1024 color__white'>Home / <span className='color__green'>FAQ</span></p>
                        </div>
                    </section>
                </div>

                <section>
                    <div className="container">
                        <div className="text-center mb-5 position-relative z-2">
                            <h5 className="bold font__size--18 text__18-1024 color__blue-1">Testimonial</h5>
                            <h2 className="medium font__size-58 text__50-1024 text__50-sm">What They Said</h2>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-md-9 col-lg-6">
                                {
                                    data.map((obj) => {
                                        return <div className="mb-4">
                                            <AccordionFaq data={obj} />
                                        </div>
                                    })
                                }
                            </div>
                        </div>


                    </div>
                </section>

                <Footer />
            </div >
        </Fragment >
    )
}

export default Faq