import React from 'react'
import { useState } from 'react'

const AccordionFaq = (props) => {
    const [Toogle, setToogle] = useState(false)
    return (
        <div className={"wrapper__accordion " + (Toogle ? "active" : "")}>
            <div className="head pointer" onClick={() => setToogle(!Toogle)}>
                <div className="d-flex align-items-center justify-content-between">
                    <h3 className='mb-0 medium font__size--18 text__18-1024'>{props.data.title}</h3>
                    <div className="arrow">
                        <img src="./../images/Icon wrap.png" className='open' alt="" />
                        <img src="./../images/Icon wrap (1).png" className='close' alt="" />
                    </div>
                </div>
            </div>
            <div className="desc">
                <p className='normal font__size--16 text__16-1024 mb-0 color__gray-1'>{props.data.desc}</p>
            </div>
        </div>
    )
}

export default AccordionFaq