import React from 'react'

const Appoinment = () => {
    return (
        <section>
            <div className="container">
                <div className="row mb-5">
                    <div className="col-lg-5">
                        <h5 className="bold font__size--18 text__18-1024 color__blue-1">Appointment</h5>
                        <h2 className="medium font__size-58 text__50-1024 text__50-sm mb-3 mb-lg-0 lh-1">Book Appoinment</h2>
                    </div>
                    <div className="col-lg-6 mt-auto">
                        <div className="d-flex flex-wrap flex-sm-nowrap align-items-center">
                            <div className="d-flex align-items-center mb-3 mb-sm-0">
                                <img src="./../images/Message.png" alt="" />
                                <h5 className='normal font__size--24 text__24-1024 ml-2 mb-0'>medhealth@gmail.com</h5>
                            </div>
                            <div className="d-flex align-items-center ml-sm-3 ml-xl-5">
                                <img src="./../images/Call.png" alt="" />
                                <h5 className='normal font__size--24 text__24-1024 ml-2 mb-0'>+123 456 789</h5>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="wrapper__form-appoinment">
                    <div className="row">
                        <div className="col-md-4 mb-3">
                            <div class="form-group">
                                <select name="" class="form-control text__16-1024 wrapper__form-control" id="">
                                    <option value="" disabled hidden selected>Select Medical Specialties</option>
                                    <option value="">a</option>
                                    <option value="">b</option>
                                    <option value="">c</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div class="form-group">
                                <select name="" class="form-control text__16-1024 wrapper__form-control" id="">
                                    <option value="" disabled hidden selected>Select Doctor</option>
                                    <option value="">a</option>
                                    <option value="">b</option>
                                    <option value="">c</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div class="form-group">
                                <select name="" class="form-control text__16-1024 wrapper__form-control" id="">
                                    <option value="" disabled hidden selected>Select Date & Time</option>
                                    <option value="">a</option>
                                    <option value="">b</option>
                                    <option value="">c</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div class="form-group">
                                <input type="text" class="form-control text__16-1024 wrapper__form-control" placeholder='Name' />
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div class="form-group">
                                <input type="text" class="form-control text__16-1024 wrapper__form-control" placeholder='Phone Number' />
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div class="form-group">
                                <input type="text" class="form-control text__16-1024 wrapper__form-control" placeholder='Email' />
                            </div>
                        </div>
                    </div>

                    <div class="form-group mb-4">
                        <textarea name="" id="" class="form-control text__16-1024 wrapper__form-control" cols="30" rows="6" placeholder='Message'></textarea>
                    </div>

                    <div className="text-center">
                        <button className='medium font__size--16 text__16-1024 btn btn__green shadow color__white'>Make Appoinment</button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Appoinment