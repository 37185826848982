import React from 'react'
import { Fragment } from 'react'
import Navbar from "./../../component/Other/Navbar"
import Footer from "./../../component/Other/Footer"

const Doctor = () => {
    const doctor = [
        {
            image: "./../images/dc (1).png",
            name: "Jacob Jones",
            title: "Cardiologist"
        },
        {
            image: "./../images/dc (8).png",
            name: "Robert Fox",
            title: "Pediatrist"
        },
        {
            image: "./../images/dc (7).png",
            name: "Marvin McKinney",
            title: "Throat Specialist"
        },
        {
            image: "./../images/dc (6).png",
            name: "Jerome Bell",
            title: "Therapy"
        },
        {
            image: "./../images/dc (5).png",
            name: "Cody Fisher",
            title: "Rehabilitation"
        },
        {
            image: "./../images/dc (4).png",
            name: "Darrell Steward",
            title: "Cardiologist"
        },
        {
            image: "./../images/dc (3).png",
            name: "Ralph Edwards",
            title: "Pediatrist"
        },
        {
            image: "./../images/dc (2).png",
            name: "Ronald Richards",
            title: "Cardiologist"
        },
    ]
    return (
        <Fragment>
            <div className="overflow-hidden">
                <div className="position-relative bg__blue-2">
                    <img src="./../images/sa (3).png" className="images__or-1" alt="" />
                    <img src="./../images/sa (2).png" className="images__or-2" alt="" />

                    <Navbar />

                    <section className="position-relative z-2">
                        <div className="container text-center py-5">
                            <h2 className='medium font__size--60 text__60-1024 text__60-sm text__60-xxs color__white'>Our Professional Doctors</h2>
                            <p className='normal font__size--16 text__16-1024 color__white'>Home / <span className='color__green'>Our Doctor</span></p>
                        </div>
                    </section>
                </div>

                <section>
                    <div className="container">
                        <div className="text-center mb-5 position-relative z-2">
                            <h5 className="bold font__size--18 text__18-1024 color__blue-1">Our Doctors</h5>
                            <h2 className="medium font__size-58 text__50-1024 text__50-sm">Meet Our Professional Doctors</h2>
                        </div>

                        <div className="row">
                            {
                                doctor.map((obj) => {
                                    return <div className="col-md-6 col-lg-3 mb-4">
                                        <div className="wrapper__card-doctor">
                                            <div className="cover position-relative mb-4">
                                                <img src={obj.image} alt="" />
                                            </div>
                                            <h5 className='medium font__size--28 text__28-1024'>{obj.name}</h5>
                                            <p className='normal font__size--16 text__16-1024 color__gray-1'>{obj.title}</p>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </section>


                <section>
                    <div className="container">
                        <div className="text-center mb-5 position-relative z-2">
                            <h5 className="bold font__size--18 text__18-1024 color__blue-1">Awards</h5>
                            <h2 className="medium font__size-58 text__50-1024 text__50-sm">Diplomas And Сertificates <br className='d-none d-md-block' />
                                Of Our Professional Doctors</h2>
                        </div>

                        <img src="./../images/sadsad.png" className='w-100 mb-5' alt="" />

                        <div className="d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-center">
                            <div className="d-flex align-items-center justify-content-sm-center justify-content-lg-start mb-4 mb-lg-0 w-sm-100 mr-0 mr-md-3 mr-lg-5">
                                <h5 className="mb-0 medium font__size--54 text__50-1024 text__50-sm color__green">4.9</h5>
                                <p className="mb-0 normal font__size--16 text__16-1024 color__black ml-3">Overall Rating, based on <br className='d-none d-xl-block' /> <span className="semi-bold color__green">3500+</span> Reviews</p>
                            </div>
                            <div className="d-flex align-items-center justify-content-sm-center justify-content-lg-start mb-4 mb-lg-0 w-sm-100 mr-0 mr-md-3 mr-lg-5">
                                <h5 className="mb-0 medium font__size--54 text__50-1024 text__50-sm color__green">99<sup className="medium font__size--32 color__green">%</sup></h5>
                                <p className="mb-0 normal font__size--16 text__16-1024 color__black ml-3">Positive Review</p>
                            </div>
                            <div className="d-flex align-items-center justify-content-sm-center justify-content-lg-start mb-4 mb-lg-0 w-sm-100 mr-0 mr-md-3 mr-lg-5">
                                <h5 className="mb-0 medium font__size--54 text__50-1024 text__50-sm color__green">9.2<sup className="medium font__size--32 color__green">k</sup></h5>
                                <p className="mb-0 normal font__size--16 text__16-1024 color__black ml-3">Happy Patients</p>
                            </div>
                            <div className="d-flex align-items-center justify-content-sm-center justify-content-lg-start mb-4 mb-lg-0 w-sm-100">
                                <h5 className="mb-0 medium font__size--54 text__50-1024 text__50-sm color__green">312</h5>
                                <p className="mb-0 normal font__size--16 text__16-1024 color__black ml-3">Health Departments</p>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </div >
        </Fragment >
    )
}

export default Doctor