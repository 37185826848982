import React from 'react'
import { NavLink } from 'react-router-dom'

const Service = () => {
    const content = [
        {
            icon: "./../images/stethoscope.png",
            title: "Medical Check",
            desc: "Prioritize your health with our thorough Medical Check services, ensuring comprehensive assessments and proactive well-being. Schedule your check-up today.",
            link: "#!"
        },
        {
            icon: "./../images/nurse.png",
            title: "Homecare Visit",
            desc: "Experience personalized care with our Homecare Visit services, bringing expert medical attention to the comfort of your home. Explore our convenient and compassionate homecare options.",
            link: "#!"
        },
        {
            icon: "./../images/ambulance.png",
            title: "Emergency Service",
            desc: "Trust our Emergency Service for prompt and skilled medical assistance. We prioritize your well-being during critical situations. Reach out to us for immediate support.",
            link: "#!"
        },
        {
            icon: "./../images/tooth.png",
            title: "Dental",
            desc: "Ensure your oral health with our specialized Dental services. From routine check-ups to advanced treatments, our team is dedicated to your dental well-being. Schedule your appointment now.",
            link: "#!"
        },
        {
            icon: "./../images/medicine.png",
            title: "Internal Medicine",
            desc: "Receive expert care with our Internal Medicine services. Our skilled professionals focus on preventive measures and comprehensive treatment plans for your overall health.",
            link: "#!"
        },
        {
            icon: "./../images/healthcare.png",
            title: "Critical Care",
            desc: "Rely on our Critical Care services for specialized medical attention during challenging times. Your well-being is our priority, and we are here to provide compassionate and critical care support.",
            link: "#!"
        }
        
    ]
    return (
        <section>
            <div className="container">
                <div className="text-center mb-5">
                    <h5 className="bold font__size--18 text__18-1024 color__blue-1">Our Service</h5>
                    <h2 className="medium font__size-58 text__50-1024 text__50-sm text__50-xs">Common Specialties</h2>
                </div>

                <div className="row justify-content-center">
                    <div className="col-xl-10">
                        <div className="row">
                            {
                                content.map((obj) => {
                                    return <div className="col-md-6 col-lg-4 mb-4">
                                        <div className="wrapper__card-service text-center">
                                            <div className="icon mb-4">
                                                <img src={obj.icon} alt="" />
                                            </div>
                                            <h4 className='medium font__size--28 text__28-1024'>{obj.title}</h4>
                                            <p className='normal font__size--16 text__16-1024 color__gray-1'>{obj.desc}</p>

                                            <NavLink to="/service/detail" className='d-inline-block'>
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <span className='bold font__size--18 text__18-1024 color__green'>Read More</span>
                                                    <img className='ml-2' src="./../images/right-arrow 2.png" alt="" />
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                })
                            }

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Service