import React, { useState, Fragment, useRef } from "react";
import { NavLink } from "react-router-dom";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import LIstArticle from "../../component/Page/LIstArticle";
import Service from "../../component/Page/Service";
import SliderTestimonial from "../../component/Slider/SliderTestimonial";
const Index = (props) => {
  const blog = [
    {
      cover: "./../images/sdasd.png",
      title: "Home Care and Nursing Service",
      desc: "Experience the convenience of expert Home Care and Nursing services tailored to your needs.",
      link: "#!",
    },
    {
      cover: "./../images/Bg (1).png",
      title: "Diabetes & Blood Pressure Check-Up",
      desc: "Prioritize your health with our specialized Diabetes and Blood Pressure check-up services.",
      link: "#!",
    },
    {
      cover: "./../images/Bg (2).png",
      title: "Online Healthcare Treatment Facilities",
      desc: "Access healthcare from the convenience of your home with our Online Healthcare Facilities.",
      link: "#!",
    }

  ]
  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="position-relative bg__blue-2">
          <img src="./../images/Group 162999.png" className="images__doctor-head d-none d-lg-block" alt="" />
          <img src="./../images/Bg.png" className="images__1" alt="" />

          <Navbar />

          <section className="position-relative z-2">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <h5 className="bold font__size--18 text__18-1024 color__white position-relative wrapper__line-left"><span className="position-relative z-2">Health Care Service</span></h5>
                  <h1 className="medium font__size--80 text__80-1024 text__80-md text__80-sm color__white">Better care for your health</h1>
                  <p className="normal font__size--16 text__16-1024 color__white my-4">Prioritize your well-being with our commitment to better care for your health, ensuring a journey towards optimal wellness.</p>
                  <NavLink to="/appoinment" className="medium font__size--16 text__16-1024 btn btn__green color__white rounded-0 shadow">Make Appoinment</NavLink>

                  <div className="mt-5">
                    <div className="d-flex align-items-center">
                      <div>
                        <h2 className="medium font__size--54 color__white">15<sup className="medium font__size--32 color__green">+</sup></h2>
                        <p className="normal font__size--16 text__16-1024 color__white mb-0">Years Experience</p>
                      </div>
                      <div className="ml-4">
                        <h2 className="medium font__size--54 color__white">99<sup className="medium font__size--32 color__green">%</sup></h2>
                        <p className="normal font__size--16 text__16-1024 color__white mb-0">Positive Review</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <section>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 mb-5 my-lg-auto">
                <img src="./../images/Image (4).png" alt="" className="images__about w-100" />
              </div>
              <div className="col-lg-6 my-auto">
                <h5 className="bold font__size--18 text__18-1024 color__blue-1">About Us</h5>
                <h2 className="medium font__size-58 text__50-1024 text__50-sm text__50-xs">Care and Compassion are Provided in Bulk</h2>
                <p className="normal font__size--16 text__16-1024 color__gray-1">At MedHealth, our ethos revolves around care and compassion, delivered in abundance. We understand that health is a personal journey, and we are dedicated to providing tailored services that prioritize your well-being. Whether through preventive care, consultations, or specialized treatments, our team is committed to creating a supportive environment for your health needs.</p>
                <p className="normal font__size--16 text__16-1024 color__gray-1">With a focus on excellence and a team driven by empathy, we go beyond conventional health services. We believe in making a positive impact on your health and life, contributing to a healthier, happier community. Join us on this journey towards well-being, where your health is our priority.</p>
                <NavLink to="/about" className="btn btn__green shadow rounded-0 px-4 color__white medium font__size--16 text__16-1024">More About Us</NavLink>
              </div>
            </div>
          </div>
        </section>

        <Service />

        <section>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 mb-5 my-lg-auto">
                <img src="./../images/dfsdfdsg.png" className="w-100" alt="" />
              </div>
              <div className="col-lg-6 my-auto">
                <h5 className="bold font__size--18 text__18-1024 color__blue-1">Our Doctor</h5>
                <h2 className="medium font__size-58 text__50-1024 text__50-sm text__50-xs">Caring For The Health And Well Being Of You And Your Family</h2>
                <p className="normal font__size--16 text__16-1024 color__gray-1">Dedicated to caring for the health and well-being of you and your family, our comprehensive services ensure personalized and compassionate medical support. Trust us for expert care, prioritizing your overall wellness.</p>
                <NavLink to="/doctor" className="btn btn__green shadow rounded-0 px-4 color__white medium font__size--16 text__16-1024">See All Doctor</NavLink>
              </div>
            </div>
          </div>
        </section>

        <section className="bg__blue-2 position-relative">
          <img src="./../images/Rectangle 7.png" className="images__or-1" alt="" />
          <img src="./../images/Rectangle 8.png" className="images__or-2" alt="" />
          <div className="container">
            <div className="text-center mb-5 position-relative z-2">
              <h5 className="bold font__size--18 text__18-1024 color__blue-1">Testimoni</h5>
              <h2 className="medium font__size-58 text__50-1024 text__50-sm text__50-xs color__white">Our Customers & Clients</h2>
            </div>
            <div className="mb-5 position-relative z-2">
              <SliderTestimonial />
            </div>

            <div className="d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-center position-relative z-2 pt-4 pt-sm-0">
              <div className="d-flex align-items-center mb-3 mb-sm-0">
                <h5 className="mb-0 medium font__size--54 color__white">4.9</h5>
                <p className="mb-0 normal font__size--16 text__16-1024 color__white ml-3">Overall Rating, based on <br className="d-none d-md-block" /> <span className="semi-bold color__green">3500+</span> Reviews</p>
              </div>
              <div className="d-flex align-items-center ml-sm-3 ml-md-5">
                <h5 className="mb-0 medium font__size--54 color__white">99<sup className="medium font__size--32 text__32-1024 text__32-xs color__green">%</sup></h5>
                <p className="mb-0 normal font__size--16 text__16-1024 color__white ml-3">Positive Review</p>
              </div>
            </div>
          </div>
        </section>

        <LIstArticle blog={blog} title={"Our Articles About Health"} about={"Blog & News"} />

        <Footer />
      </div >
    </Fragment >
  );
};

export default Index;
