import React from 'react'
import { Fragment } from 'react'
import Navbar from "./../../component/Other/Navbar"
import Footer from "./../../component/Other/Footer"
import { AmbulanceIcons, CheckIcons, HealthCareIcons, MedicineIcons, NurseIcons, ToothIcons } from '../../component/Icon/Icons'
import Operating from '../../component/Page/Operating'
import Appoinment from '../../component/Page/Appoinment'

const DetailService = () => {
    return (
        <Fragment>
            <div className="overflow-hidden">
                <div className="position-relative bg__blue-2">
                    <img src="./../images/sa (3).png" className="images__or-1" alt="" />
                    <img src="./../images/sa (2).png" className="images__or-2" alt="" />

                    <Navbar />

                    <section className="position-relative z-2">
                        <div className="container text-center py-5">
                            <h2 className='medium font__size--60 text__60-1024 text__60-sm text__60-xxs color__white'>Detail Service</h2>
                            <p className='normal font__size--16 text__16-1024 color__white'>Home / <span>Service</span> <span className='color__green'>/ Detail Service</span>
                            </p>
                        </div>
                    </section>
                </div>

                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-xl-3 mb-5 mb-md-0">
                                <h5 className='medium font__size--24 text__24-1024 mb-4'>Common Specialties</h5>

                                <div className="wrapper__list-menu-side">
                                    <a href="#!" className='normal font__size--20 text__20-1024 color__black active'>
                                        <div className="d-flex align-items-center">
                                            <CheckIcons />
                                            <span className='ml-3'>Medical Check</span>
                                        </div>
                                    </a>
                                    <a href="#!" className='normal font__size--20 text__20-1024 color__black'>
                                        <div className="d-flex align-items-center">
                                            <NurseIcons />
                                            <span className='ml-3'>Homecare Visit</span>
                                        </div>
                                    </a>
                                    <a href="#!" className='normal font__size--20 text__20-1024 color__black'>
                                        <div className="d-flex align-items-center">
                                            <AmbulanceIcons />
                                            <span className='ml-3'>Emergency Service</span>
                                        </div>
                                    </a>
                                    <a href="#!" className='normal font__size--20 text__20-1024 color__black'>
                                        <div className="d-flex align-items-center">
                                            <ToothIcons />
                                            <span className='ml-3'>Dental</span>
                                        </div>
                                    </a>
                                    <a href="#!" className='normal font__size--20 text__20-1024 color__black'>
                                        <div className="d-flex align-items-center">
                                            <MedicineIcons />
                                            <span className='ml-3'>Internal Medicine</span>
                                        </div>
                                    </a>
                                    <a href="#!" className='normal font__size--20 text__20-1024 color__black'>
                                        <div className="d-flex align-items-center">
                                            <HealthCareIcons />
                                            <span className='ml-3'>Critical Care</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-8 offset-xl-1">
                                <h2 className='medium font__size--40 text__40-1024 text__40-md text__40-mm mb-4'>Medical Check</h2>
                                <p className='normal font__size--20 text__20-1024 color__gray-1 lh-2 mb-4'>Discover the pinnacle of healthcare with our Medical Check service at MedHealth. We prioritize your well-being by offering a comprehensive and thorough examination tailored to your unique health needs. Our state-of-the-art diagnostics and expert medical team ensure that every aspect of your health is meticulously assessed, providing you with a detailed understanding of your current health status. </p>
                                <p className='normal font__size--20 text__20-1024 color__gray-1 lh-2 mb-4'>From preventive screenings to personalized consultations, our Medical Check service is designed to empower you with valuable insights and guide you on the path to optimal health. Trust us to go beyond routine check-ups, delivering precision and excellence in healthcare to enhance your overall well-being.</p>

                                <div className="row">
                                    <div className="col-lg-6 mb-4 mb-lg-0">
                                        <img src="./../images/asas (1).png" className='images__service-detail' alt="" />
                                    </div>
                                    <div className="col-lg-6">
                                        <img src="./../images/asas (2).png" className='images__service-detail' alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <Operating />

                <div className="bg__gray-2">
                    <Appoinment />

                    <Footer />
                </div>
            </div >
        </Fragment >
    )
}

export default DetailService